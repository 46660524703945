.component {
  padding-top: 24px;
}

.remainingTimeBox {
  text-align: right;
}

.randomAccessContainer {
  float: right;
}
